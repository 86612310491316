import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import FeaturesWithImages from "../components/features_with_images"
import Benefits from "../components/benefits"
import SignupBanner from "../components/signupBanner"
import { graphql, Link } from "gatsby" // to query for image data

const PalletPage = ({ data }) => (
  <Layout>
    <SEO
      title="Pallet Calculator"
      description="Create the ultimate pallet configurations with Cargo-Planners 3D pallet calculator. Handles mixed pallets, interlocking, overhang and more. 10 days for free"
    />
    <Hero
      image={data.hero}
      title="Pallet Calculator"
      body="Stop wasting time and space with manual palletization. Create the ultimate pallet configuration with Cargo-Planners interactive pallet calculator."
      className="bg-white"
    />
    <hr />
    <FeaturesWithImages
      features={[
        {
          title: "Mixed items",
          image: data.mixed,
          content: (
            <span>
              Quickly calculate how much will fit onto a pallet. Cargo-Planner
              lets you transform Excel lists into a optimized palletization
              solution in a few clicks. Our solution gives you a 3D view of what
              our state-of-the-art packing algorithm suggests. Not happy with
              the results? Moving and re-stacking items is as easy as dragging a
              box with your mouse.
            </span>
          ),
        },
        {
          title: "Out-of-gauge cargo",
          image: data.oog,
          content: (
            <span>
              Do you have Out of Gauge cargoes? No problem! Cargo-Planner let's
              you select multiple pallet types. If none of them are large enough
              to fit your cargo, we will load the item with balanced overhang on
              each side.
            </span>
          ),
        },
        {
          title: "Custom pallets & boxes",
          image: data.custom,
          content: (
            <span>
              Our{" "}
              <Link
                to="/equipment-library/"
                className="hover:underline text-blue-900"
              >
                equipment library
              </Link>{" "}
              contains the most common pallet types, such as EU Pallets and GMA
              Pallets. Do you have specialized and custom equipment? Design your
              own pallet or box with our <i>Container Builder</i> in a few
              minutes. We can account for inner walls, multiple floors, frames,
              doors, contours and more.
            </span>
          ),
        },
        {
          title: "See the bigger picture",
          image: data.pallets,
          content: (
            <span>
              Cargo-Planner is more than just a palletization calculator. Load
              your pallets onto sea containers, trucks, trailers, air pallets or
              even other pallets, to get an understanding of the bigger picture.
              All modules included.
            </span>
          ),
        },
      ]}
    ></FeaturesWithImages>
    <Benefits></Benefits>
    <SignupBanner></SignupBanner>
  </Layout>
)

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`

export const query = graphql`
  {
    pallets: file(
      relativePath: { eq: "containers/container-with-pallets.png" }
    ) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    oog: file(relativePath: { eq: "pallets/oog.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    custom: file(relativePath: { eq: "pallets/pallet_custom_box.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    hero: file(relativePath: { eq: "pallets/pallet_g4.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    mixed: file(relativePath: { eq: "pallets/pallet_loadplan.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

export default PalletPage
